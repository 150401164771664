/* stylelint-disable */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.pointer-events-none {
  pointer-events: none
}

.pointer-events-auto {
  pointer-events: auto
}

.\!visible {
  visibility: visible !important
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.collapse {
  visibility: collapse
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.\!relative {
  position: relative !important
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.-inset-1 {
  inset: -0.25rem
}

.inset-0 {
  inset: 0px
}

.-bottom-12 {
  bottom: -3rem
}

.-top-1 {
  top: -0.25rem
}

.-top-12 {
  top: -3rem
}

.-top-4 {
  top: -1rem
}

.end-1 {
  inset-inline-end: 0.25rem
}

.end-2 {
  inset-inline-end: 0.5rem
}

.end-7 {
  inset-inline-end: 1.75rem
}

.left-0 {
  left: 0px
}

.left-1\/2 {
  left: 50%
}

.left-2 {
  left: 0.5rem
}

.right-0 {
  right: 0px
}

.right-1 {
  right: 0.25rem
}

.right-2 {
  right: 0.5rem
}

.right-4 {
  right: 1rem
}

.start-1 {
  inset-inline-start: 0.25rem
}

.top-0 {
  top: 0px
}

.top-1 {
  top: 0.25rem
}

.top-10 {
  top: 2.5rem
}

.top-105 {
  top: 0.375rem
}

.top-4 {
  top: 1rem
}

.isolate {
  isolation: isolate
}

.isolation-auto {
  isolation: auto
}

.z-20 {
  z-index: 20
}

.z-50 {
  z-index: 50
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.col-span-3 {
  grid-column: span 3 / span 3
}

.float-right {
  float: right
}

.float-left {
  float: left
}

.float-none {
  float: none
}

.clear-left {
  clear: left
}

.clear-right {
  clear: right
}

.clear-both {
  clear: both
}

.clear-none {
  clear: none
}

.m-0 {
  margin: 0px
}

.m-0\! {
  margin: 0px !important
}

.m-005 {
  margin: 0.125rem
}

.m-1 {
  margin: 0.25rem
}

.m-10 {
  margin: 2.5rem
}

.m-105 {
  margin: 0.375rem
}

.m-11 {
  margin: 2.75rem
}

.m-12 {
  margin: 3rem
}

.m-14 {
  margin: 3.5rem
}

.m-16 {
  margin: 4rem
}

.m-18 {
  margin: 4.5rem
}

.m-2 {
  margin: 0.5rem
}

.m-20 {
  margin: 5rem
}

.m-205 {
  margin: 0.625rem
}

.m-24 {
  margin: 6rem
}

.m-28 {
  margin: 7rem
}

.m-3 {
  margin: 0.75rem
}

.m-305 {
  margin: 0.875rem
}

.m-32 {
  margin: 8rem
}

.m-36 {
  margin: 9rem
}

.m-4 {
  margin: 1rem
}

.m-40 {
  margin: 10rem
}

.m-44 {
  margin: 11rem
}

.m-48 {
  margin: 12rem
}

.m-5 {
  margin: 1.25rem
}

.m-52 {
  margin: 13rem
}

.m-56 {
  margin: 14rem
}

.m-6 {
  margin: 1.5rem
}

.m-60 {
  margin: 15rem
}

.m-64 {
  margin: 16rem
}

.m-7 {
  margin: 1.75rem
}

.m-72 {
  margin: 18rem
}

.m-8 {
  margin: 2rem
}

.m-80 {
  margin: 20rem
}

.m-9 {
  margin: 2.25rem
}

.m-96 {
  margin: 24rem
}

.m-px {
  margin: 1px
}

.-mx-205 {
  margin-left: -0.625rem;
  margin-right: -0.625rem
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px
}

.mx-205 {
  margin-left: 0.625rem;
  margin-right: 0.625rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem
}

.my-18 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem
}

.my-205 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

.my-305 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.-ml-7 {
  margin-left: -1.75rem
}

.-mr-4 {
  margin-right: -1rem
}

.-mr-7 {
  margin-right: -1.75rem
}

.-mt-005 {
  margin-top: -0.125rem
}

.-mt-2 {
  margin-top: -0.5rem
}

.-mt-3 {
  margin-top: -0.75rem
}

.mb-0 {
  margin-bottom: 0px
}

.mb-1 {
  margin-bottom: 0.25rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.mb-12 {
  margin-bottom: 3rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mb-20 {
  margin-bottom: 5rem
}

.mb-205 {
  margin-bottom: 0.625rem
}

.mb-24 {
  margin-bottom: 6rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mb-305 {
  margin-bottom: 0.875rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mb-8 {
  margin-bottom: 2rem
}

.me-1 {
  -webkit-margin-end: 0.25rem;
          margin-inline-end: 0.25rem
}

.ml-0 {
  margin-left: 0px
}

.ml-1 {
  margin-left: 0.25rem
}

.ml-105 {
  margin-left: 0.375rem
}

.ml-2 {
  margin-left: 0.5rem
}

.ml-205 {
  margin-left: 0.625rem
}

.ml-3 {
  margin-left: 0.75rem
}

.ml-4 {
  margin-left: 1rem
}

.ml-5 {
  margin-left: 1.25rem
}

.ml-auto {
  margin-left: auto
}

.mr-1 {
  margin-right: 0.25rem
}

.mr-12 {
  margin-right: 3rem
}

.mr-2 {
  margin-right: 0.5rem
}

.mr-3 {
  margin-right: 0.75rem
}

.mr-4 {
  margin-right: 1rem
}

.mr-6 {
  margin-right: 1.5rem
}

.mr-8 {
  margin-right: 2rem
}

.mr-auto {
  margin-right: auto
}

.mt-0 {
  margin-top: 0px
}

.mt-1 {
  margin-top: 0.25rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mt-12 {
  margin-top: 3rem
}

.mt-18 {
  margin-top: 4.5rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-20 {
  margin-top: 5rem
}

.mt-205 {
  margin-top: 0.625rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mt-8 {
  margin-top: 2rem
}

.box-border {
  box-sizing: border-box
}

.box-content {
  box-sizing: content-box
}

.line-clamp-none {
  overflow: visible;
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-line-clamp: none
}

.\!block {
  display: block !important
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.\!inline {
  display: inline !important
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.\!table {
  display: table !important
}

.table {
  display: table
}

.inline-table {
  display: inline-table
}

.table-caption {
  display: table-caption
}

.table-cell {
  display: table-cell
}

.table-column {
  display: table-column
}

.table-column-group {
  display: table-column-group
}

.table-footer-group {
  display: table-footer-group
}

.table-header-group {
  display: table-header-group
}

.table-row-group {
  display: table-row-group
}

.table-row {
  display: table-row
}

.flow-root {
  display: flow-root
}

.\!grid {
  display: grid !important
}

.grid {
  display: grid
}

.inline-grid {
  display: inline-grid
}

.contents {
  display: contents
}

.list-item {
  display: list-item
}

.\!hidden {
  display: none !important
}

.hidden {
  display: none
}

.h-0 {
  height: 0px
}

.h-005 {
  height: 2px
}

.h-1 {
  height: 0.25rem
}

.h-1\/6 {
  height: 16.666667%
}

.h-10 {
  height: 2.5rem
}

.h-105 {
  height: 0.375rem
}

.h-11 {
  height: 2.75rem
}

.h-12 {
  height: 3rem
}

.h-14 {
  height: 3.5rem
}

.h-16 {
  height: 4rem
}

.h-18 {
  height: 4.5rem
}

.h-2 {
  height: 0.5rem
}

.h-20 {
  height: 5rem
}

.h-205 {
  height: 0.625rem
}

.h-24 {
  height: 6rem
}

.h-28 {
  height: 7rem
}

.h-3 {
  height: 0.75rem
}

.h-305 {
  height: 0.875rem
}

.h-32 {
  height: 8rem
}

.h-36 {
  height: 9rem
}

.h-4 {
  height: 1rem
}

.h-40 {
  height: 10rem
}

.h-44 {
  height: 11rem
}

.h-48 {
  height: 12rem
}

.h-5 {
  height: 1.25rem
}

.h-52 {
  height: 13rem
}

.h-56 {
  height: 14rem
}

.h-6 {
  height: 1.5rem
}

.h-60 {
  height: 15rem
}

.h-64 {
  height: 16rem
}

.h-7 {
  height: 1.75rem
}

.h-72 {
  height: 18rem
}

.h-8 {
  height: 2rem
}

.h-80 {
  height: 20rem
}

.h-9 {
  height: 2.25rem
}

.h-96 {
  height: 24rem
}

.h-full {
  height: 100%
}

.h-px {
  height: 1px
}

.h-screen {
  height: 100vh
}

.w-1\/12 {
  width: 8.333333%
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-1\/4 {
  width: 25%
}

.w-1\/5 {
  width: 20%
}

.w-1\/6 {
  width: 16.666667%
}

.w-14 {
  width: 3.5rem
}

.w-16 {
  width: 4rem
}

.w-2\/12 {
  width: 16.666667%
}

.w-2\/3 {
  width: 66.666667%
}

.w-2\/4 {
  width: 50%
}

.w-24 {
  width: 6rem
}

.w-32 {
  width: 8rem
}

.w-36 {
  width: 9rem
}

.w-4 {
  width: 1rem
}

.w-44 {
  width: 11rem
}

.w-5 {
  width: 1.25rem
}

.w-80 {
  width: 20rem
}

.w-auto {
  width: auto
}

.w-full {
  width: 100%
}

.w-max {
  width: -moz-max-content;
  width: max-content
}

.max-w-2xl {
  max-width: 42rem
}

.max-w-full {
  max-width: 100%
}

.max-w-md {
  max-width: 28rem
}

.max-w-sm {
  max-width: 24rem
}

.flex-1 {
  flex: 1 1 0%
}

.flex-shrink {
  flex-shrink: 1
}

.shrink {
  flex-shrink: 1
}

.shrink-0 {
  flex-shrink: 0
}

.flex-grow {
  flex-grow: 1
}

.grow {
  flex-grow: 1
}

.table-auto {
  table-layout: auto
}

.table-fixed {
  table-layout: fixed
}

.caption-top {
  caption-side: top
}

.caption-bottom {
  caption-side: bottom
}

.border-collapse {
  border-collapse: collapse
}

.border-separate {
  border-collapse: separate
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.\!transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform-cpu {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform-none {
  transform: none
}

.cursor-help {
  cursor: help
}

.cursor-pointer {
  cursor: pointer
}

.touch-auto {
  touch-action: auto
}

.touch-none {
  touch-action: none
}

.touch-pan-x {
  --tw-pan-x: pan-x;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom)
}

.touch-pan-left {
  --tw-pan-x: pan-left;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom)
}

.touch-pan-right {
  --tw-pan-x: pan-right;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom)
}

.touch-pan-y {
  --tw-pan-y: pan-y;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom)
}

.touch-pan-up {
  --tw-pan-y: pan-up;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom)
}

.touch-pan-down {
  --tw-pan-y: pan-down;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom)
}

.touch-pinch-zoom {
  --tw-pinch-zoom: pinch-zoom;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom)
}

.touch-manipulation {
  touch-action: manipulation
}

.select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none
}

.select-text {
  -webkit-user-select: text;
     -moz-user-select: text;
          user-select: text
}

.select-all {
  -webkit-user-select: all;
     -moz-user-select: all;
          user-select: all
}

.select-auto {
  -webkit-user-select: auto;
     -moz-user-select: auto;
          user-select: auto
}

.resize-none {
  resize: none
}

.resize-y {
  resize: vertical
}

.resize-x {
  resize: horizontal
}

.resize {
  resize: both
}

.snap-none {
  scroll-snap-type: none
}

.snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness)
}

.snap-y {
  scroll-snap-type: y var(--tw-scroll-snap-strictness)
}

.snap-both {
  scroll-snap-type: both var(--tw-scroll-snap-strictness)
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory
}

.snap-proximity {
  --tw-scroll-snap-strictness: proximity
}

.snap-start {
  scroll-snap-align: start
}

.snap-end {
  scroll-snap-align: end
}

.snap-center {
  scroll-snap-align: center
}

.snap-align-none {
  scroll-snap-align: none
}

.snap-normal {
  scroll-snap-stop: normal
}

.snap-always {
  scroll-snap-stop: always
}

.list-inside {
  list-style-position: inside
}

.list-outside {
  list-style-position: outside
}

.list-none {
  list-style-type: none
}

.appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none
}

.break-before-auto {
  -moz-column-break-before: auto;
       break-before: auto
}

.break-before-avoid {
  -moz-column-break-before: avoid;
       break-before: avoid
}

.break-before-all {
  -moz-column-break-before: all;
       break-before: all
}

.break-before-avoid-page {
  -moz-column-break-before: avoid;
       break-before: avoid-page
}

.break-before-page {
  -moz-column-break-before: page;
       break-before: page
}

.break-before-left {
  -moz-column-break-before: left;
       break-before: left
}

.break-before-right {
  -moz-column-break-before: right;
       break-before: right
}

.break-before-column {
  -moz-column-break-before: column;
       break-before: column
}

.break-inside-auto {
  -moz-column-break-inside: auto;
       break-inside: auto
}

.break-inside-avoid {
  -moz-column-break-inside: avoid;
       break-inside: avoid
}

.break-inside-avoid-page {
  break-inside: avoid-page
}

.break-inside-avoid-column {
  -moz-column-break-inside: avoid;
       break-inside: avoid-column
}

.break-after-auto {
  -moz-column-break-after: auto;
       break-after: auto
}

.break-after-avoid {
  -moz-column-break-after: avoid;
       break-after: avoid
}

.break-after-all {
  -moz-column-break-after: all;
       break-after: all
}

.break-after-avoid-page {
  -moz-column-break-after: avoid;
       break-after: avoid-page
}

.break-after-page {
  -moz-column-break-after: page;
       break-after: page
}

.break-after-left {
  -moz-column-break-after: left;
       break-after: left
}

.break-after-right {
  -moz-column-break-after: right;
       break-after: right
}

.break-after-column {
  -moz-column-break-after: column;
       break-after: column
}

.grid-flow-row {
  grid-auto-flow: row
}

.grid-flow-col {
  grid-auto-flow: column
}

.grid-flow-dense {
  grid-auto-flow: dense
}

.grid-flow-row-dense {
  grid-auto-flow: row dense
}

.grid-flow-col-dense {
  grid-auto-flow: column dense
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse
}

.flex-nowrap {
  flex-wrap: nowrap
}

.place-content-center {
  place-content: center
}

.place-content-start {
  place-content: start
}

.place-content-end {
  place-content: end
}

.place-content-between {
  place-content: space-between
}

.place-content-around {
  place-content: space-around
}

.place-content-evenly {
  place-content: space-evenly
}

.place-content-baseline {
  place-content: baseline
}

.place-content-stretch {
  place-content: stretch
}

.place-items-start {
  place-items: start
}

.place-items-end {
  place-items: end
}

.place-items-center {
  place-items: center
}

.place-items-baseline {
  place-items: baseline
}

.place-items-stretch {
  place-items: stretch
}

.content-normal {
  align-content: normal
}

.content-center {
  align-content: center
}

.content-start {
  align-content: flex-start
}

.content-end {
  align-content: flex-end
}

.content-between {
  align-content: space-between
}

.content-around {
  align-content: space-around
}

.content-evenly {
  align-content: space-evenly
}

.content-baseline {
  align-content: baseline
}

.content-stretch {
  align-content: stretch
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.items-baseline {
  align-items: baseline
}

.items-stretch {
  align-items: stretch
}

.justify-normal {
  justify-content: normal
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-around {
  justify-content: space-around
}

.justify-evenly {
  justify-content: space-evenly
}

.justify-stretch {
  justify-content: stretch
}

.justify-items-start {
  justify-items: start
}

.justify-items-end {
  justify-items: end
}

.justify-items-center {
  justify-items: center
}

.justify-items-stretch {
  justify-items: stretch
}

.gap-2 {
  gap: 0.5rem
}

.gap-4 {
  gap: 1rem
}

.gap-6 {
  gap: 1.5rem
}

.gap-y-2 {
  row-gap: 0.5rem
}

.gap-y-4 {
  row-gap: 1rem
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1
}

.space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}

.divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 1
}

.divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 1
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid
}

.divide-dashed > :not([hidden]) ~ :not([hidden]) {
  border-style: dashed
}

.divide-dotted > :not([hidden]) ~ :not([hidden]) {
  border-style: dotted
}

.divide-double > :not([hidden]) ~ :not([hidden]) {
  border-style: double
}

.divide-none > :not([hidden]) ~ :not([hidden]) {
  border-style: none
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(238 238 238 / var(--tw-divide-opacity))
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-divide-opacity))
}

.place-self-auto {
  place-self: auto
}

.place-self-start {
  place-self: start
}

.place-self-end {
  place-self: end
}

.place-self-center {
  place-self: center
}

.place-self-stretch {
  place-self: stretch
}

.self-auto {
  align-self: auto
}

.self-start {
  align-self: flex-start
}

.self-end {
  align-self: flex-end
}

.self-center {
  align-self: center
}

.self-stretch {
  align-self: stretch
}

.self-baseline {
  align-self: baseline
}

.justify-self-auto {
  justify-self: auto
}

.justify-self-start {
  justify-self: start
}

.justify-self-end {
  justify-self: end
}

.justify-self-center {
  justify-self: center
}

.justify-self-stretch {
  justify-self: stretch
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-clip {
  overflow: clip
}

.overflow-visible {
  overflow: visible
}

.overflow-scroll {
  overflow: scroll
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.overflow-y-hidden {
  overflow-y: hidden
}

.overflow-x-clip {
  overflow-x: clip
}

.overflow-y-clip {
  overflow-y: clip
}

.overflow-x-visible {
  overflow-x: visible
}

.overflow-y-visible {
  overflow-y: visible
}

.overflow-x-scroll {
  overflow-x: scroll
}

.overflow-y-scroll {
  overflow-y: scroll
}

.overscroll-auto {
  overscroll-behavior: auto
}

.overscroll-contain {
  overscroll-behavior: contain
}

.overscroll-none {
  overscroll-behavior: none
}

.overscroll-y-auto {
  overscroll-behavior-y: auto
}

.overscroll-y-contain {
  overscroll-behavior-y: contain
}

.overscroll-y-none {
  overscroll-behavior-y: none
}

.overscroll-x-auto {
  overscroll-behavior-x: auto
}

.overscroll-x-contain {
  overscroll-behavior-x: contain
}

.overscroll-x-none {
  overscroll-behavior-x: none
}

.scroll-auto {
  scroll-behavior: auto
}

.scroll-smooth {
  scroll-behavior: smooth
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.overflow-ellipsis {
  text-overflow: ellipsis
}

.text-ellipsis {
  text-overflow: ellipsis
}

.text-clip {
  text-overflow: clip
}

.hyphens-none {
  -webkit-hyphens: none;
          hyphens: none
}

.hyphens-manual {
  -webkit-hyphens: manual;
          hyphens: manual
}

.hyphens-auto {
  -webkit-hyphens: auto;
          hyphens: auto
}

.whitespace-normal {
  white-space: normal
}

.whitespace-nowrap {
  white-space: nowrap
}

.whitespace-pre {
  white-space: pre
}

.whitespace-pre-line {
  white-space: pre-line
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.whitespace-break-spaces {
  white-space: break-spaces
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal
}

.break-words {
  overflow-wrap: break-word
}

.break-all {
  word-break: break-all
}

.break-keep {
  word-break: keep-all
}

.rounded {
  border-radius: 0.25rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-sm {
  border-radius: 0.125rem
}

.rounded-xl {
  border-radius: 0.75rem
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

.rounded-e {
  border-start-end-radius: 0.25rem;
  border-end-end-radius: 0.25rem
}

.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

.rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem
}

.rounded-s {
  border-start-start-radius: 0.25rem;
  border-end-start-radius: 0.25rem
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem
}

.rounded-bl {
  border-bottom-left-radius: 0.25rem
}

.rounded-br {
  border-bottom-right-radius: 0.25rem
}

.rounded-ee {
  border-end-end-radius: 0.25rem
}

.rounded-es {
  border-end-start-radius: 0.25rem
}

.rounded-se {
  border-start-end-radius: 0.25rem
}

.rounded-ss {
  border-start-start-radius: 0.25rem
}

.rounded-tl {
  border-top-left-radius: 0.25rem
}

.rounded-tr {
  border-top-right-radius: 0.25rem
}

.border {
  border-width: 1px
}

.border-0 {
  border-width: 0px
}

.border-2 {
  border-width: 2px
}

.border-8 {
  border-width: 8px
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.border-e {
  border-inline-end-width: 1px
}

.border-l {
  border-left-width: 1px
}

.border-r {
  border-right-width: 1px
}

.border-s {
  border-inline-start-width: 1px
}

.border-t {
  border-top-width: 1px
}

.border-t-0 {
  border-top-width: 0px
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-dotted {
  border-style: dotted
}

.border-double {
  border-style: double
}

.border-hidden {
  border-style: hidden
}

.border-none {
  border-style: none
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(238 238 238 / var(--tw-border-opacity))
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-border-opacity))
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(158 203 10 / var(--tw-border-opacity))
}

.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(109 44 100 / var(--tw-border-opacity))
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.bg-\[rgb\(255\2c 0\2c 0\)\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0 / var(--tw-bg-opacity))
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(171 231 248 / var(--tw-bg-opacity))
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 93 144 / var(--tw-bg-opacity))
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity))
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity))
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 252 204 / var(--tw-bg-opacity))
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(158 203 10 / var(--tw-bg-opacity))
}

.bg-legacy-gray-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(174 207 209 / var(--tw-bg-opacity))
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 92 150 / var(--tw-bg-opacity))
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(131 60 121 / var(--tw-bg-opacity))
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(109 44 100 / var(--tw-bg-opacity))
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(211 43 67 / var(--tw-bg-opacity))
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 178 164 / var(--tw-bg-opacity))
}

.bg-teal-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(43 132 117 / var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 244 210 / var(--tw-bg-opacity))
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 206 70 / var(--tw-bg-opacity))
}

.decoration-slice {
  -webkit-box-decoration-break: slice;
          box-decoration-break: slice
}

.decoration-clone {
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone
}

.box-decoration-slice {
  -webkit-box-decoration-break: slice;
          box-decoration-break: slice
}

.box-decoration-clone {
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone
}

.bg-cover {
  background-size: cover
}

.bg-fixed {
  background-attachment: fixed
}

.bg-local {
  background-attachment: local
}

.bg-scroll {
  background-attachment: scroll
}

.bg-clip-border {
  background-clip: border-box
}

.bg-clip-padding {
  background-clip: padding-box
}

.bg-clip-content {
  background-clip: content-box
}

.bg-clip-text {
  -webkit-background-clip: text;
          background-clip: text
}

.bg-repeat {
  background-repeat: repeat
}

.bg-no-repeat {
  background-repeat: no-repeat
}

.bg-repeat-x {
  background-repeat: repeat-x
}

.bg-repeat-y {
  background-repeat: repeat-y
}

.bg-repeat-round {
  background-repeat: round
}

.bg-repeat-space {
  background-repeat: space
}

.bg-origin-border {
  background-origin: border-box
}

.bg-origin-padding {
  background-origin: padding-box
}

.bg-origin-content {
  background-origin: content-box
}

.object-contain {
  -o-object-fit: contain;
     object-fit: contain
}

.object-cover {
  -o-object-fit: cover;
     object-fit: cover
}

.object-fill {
  -o-object-fit: fill;
     object-fit: fill
}

.object-none {
  -o-object-fit: none;
     object-fit: none
}

.object-scale-down {
  -o-object-fit: scale-down;
     object-fit: scale-down
}

.p-0 {
  padding: 0px
}

.p-0\! {
  padding: 0px !important
}

.p-1 {
  padding: 0.25rem
}

.p-2 {
  padding: 0.5rem
}

.p-20 {
  padding: 5rem
}

.p-205 {
  padding: 0.625rem
}

.p-3 {
  padding: 0.75rem
}

.p-4 {
  padding: 1rem
}

.p-6 {
  padding: 1.5rem
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-205 {
  padding-left: 0.625rem;
  padding-right: 0.625rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem
}

.pb-0 {
  padding-bottom: 0px
}

.pb-12 {
  padding-bottom: 3rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pb-205 {
  padding-bottom: 0.625rem
}

.pb-24 {
  padding-bottom: 6rem
}

.pb-3 {
  padding-bottom: 0.75rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pb-5 {
  padding-bottom: 1.25rem
}

.pb-6 {
  padding-bottom: 1.5rem
}

.pb-7 {
  padding-bottom: 1.75rem
}

.pb-8 {
  padding-bottom: 2rem
}

.pl-0\! {
  padding-left: 0px !important
}

.pl-2 {
  padding-left: 0.5rem
}

.pl-5 {
  padding-left: 1.25rem
}

.pl-6 {
  padding-left: 1.5rem
}

.pr-0\! {
  padding-right: 0px !important
}

.pr-1 {
  padding-right: 0.25rem
}

.pr-10 {
  padding-right: 2.5rem
}

.pr-2 {
  padding-right: 0.5rem
}

.pr-3 {
  padding-right: 0.75rem
}

.pr-4 {
  padding-right: 1rem
}

.pr-6 {
  padding-right: 1.5rem
}

.pr-9 {
  padding-right: 2.25rem
}

.pt-0 {
  padding-top: 0px
}

.pt-1 {
  padding-top: 0.25rem
}

.pt-12 {
  padding-top: 3rem
}

.pt-16 {
  padding-top: 4rem
}

.pt-205 {
  padding-top: 0.625rem
}

.pt-24 {
  padding-top: 6rem
}

.pt-28 {
  padding-top: 7rem
}

.pt-3 {
  padding-top: 0.75rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-6 {
  padding-top: 1.5rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-justify {
  text-align: justify
}

.text-start {
  text-align: start
}

.text-end {
  text-align: end
}

.align-baseline {
  vertical-align: baseline
}

.align-top {
  vertical-align: top
}

.align-middle {
  vertical-align: middle
}

.align-bottom {
  vertical-align: bottom
}

.align-text-top {
  vertical-align: text-top
}

.align-text-bottom {
  vertical-align: text-bottom
}

.align-sub {
  vertical-align: sub
}

.align-super {
  vertical-align: super
}

.font-mono {
  font-family: Monaco, Menlo, Consolas, ui-monospace, "Courier New", monospace
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem
}

.text-5xl {
  font-size: 3rem;
  line-height: 1
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.font-bold {
  font-weight: 700
}

.font-normal {
  font-weight: 400
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.italic {
  font-style: italic
}

.not-italic {
  font-style: normal
}

.normal-nums {
  font-variant-numeric: normal
}

.ordinal {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.slashed-zero {
  --tw-slashed-zero: slashed-zero;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.lining-nums {
  --tw-numeric-figure: lining-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.oldstyle-nums {
  --tw-numeric-figure: oldstyle-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.proportional-nums {
  --tw-numeric-spacing: proportional-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.diagonal-fractions {
  --tw-numeric-fraction: diagonal-fractions;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.stacked-fractions {
  --tw-numeric-fraction: stacked-fractions;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.leading-5 {
  line-height: 1.25rem
}

.leading-6 {
  line-height: 1.5rem
}

.leading-tight {
  line-height: 1.25
}

.tracking-tight {
  letter-spacing: -0.025em
}

.text-\[\#336699\]\/\[\.35\] {
  color: rgb(51 102 153 / .35)
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(0 160 200 / var(--tw-text-opacity))
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(238 238 238 / var(--tw-text-opacity))
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(145 145 145 / var(--tw-text-opacity))
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(123 123 123 / var(--tw-text-opacity))
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(99 99 99 / var(--tw-text-opacity))
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(63 63 63 / var(--tw-text-opacity))
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(40 40 40 / var(--tw-text-opacity))
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgb(245 252 204 / var(--tw-text-opacity))
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(158 203 10 / var(--tw-text-opacity))
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(53 124 0 / var(--tw-text-opacity))
}

.text-muted {
  --tw-text-opacity: 1;
  color: rgb(99 99 99 / var(--tw-text-opacity))
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(109 44 100 / var(--tw-text-opacity))
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(211 43 67 / var(--tw-text-opacity))
}

.text-teal-700 {
  --tw-text-opacity: 1;
  color: rgb(43 132 117 / var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgb(251 232 175 / var(--tw-text-opacity))
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(246 206 70 / var(--tw-text-opacity))
}

.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(196 68 0 / var(--tw-text-opacity))
}

.underline {
  text-decoration-line: underline
}

.overline {
  text-decoration-line: overline
}

.line-through {
  text-decoration-line: line-through
}

.no-underline {
  text-decoration-line: none
}

.decoration-solid {
  text-decoration-style: solid
}

.decoration-double {
  text-decoration-style: double
}

.decoration-dotted {
  text-decoration-style: dotted
}

.decoration-dashed {
  text-decoration-style: dashed
}

.decoration-wavy {
  text-decoration-style: wavy
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto
}

.opacity-0 {
  opacity: 0
}

.bg-blend-normal {
  background-blend-mode: normal
}

.bg-blend-multiply {
  background-blend-mode: multiply
}

.bg-blend-screen {
  background-blend-mode: screen
}

.bg-blend-overlay {
  background-blend-mode: overlay
}

.bg-blend-darken {
  background-blend-mode: darken
}

.bg-blend-lighten {
  background-blend-mode: lighten
}

.bg-blend-color-dodge {
  background-blend-mode: color-dodge
}

.bg-blend-color-burn {
  background-blend-mode: color-burn
}

.bg-blend-hard-light {
  background-blend-mode: hard-light
}

.bg-blend-soft-light {
  background-blend-mode: soft-light
}

.bg-blend-difference {
  background-blend-mode: difference
}

.bg-blend-exclusion {
  background-blend-mode: exclusion
}

.bg-blend-hue {
  background-blend-mode: hue
}

.bg-blend-saturation {
  background-blend-mode: saturation
}

.bg-blend-color {
  background-blend-mode: color
}

.bg-blend-luminosity {
  background-blend-mode: luminosity
}

.mix-blend-normal {
  mix-blend-mode: normal
}

.mix-blend-multiply {
  mix-blend-mode: multiply
}

.mix-blend-screen {
  mix-blend-mode: screen
}

.mix-blend-overlay {
  mix-blend-mode: overlay
}

.mix-blend-darken {
  mix-blend-mode: darken
}

.mix-blend-lighten {
  mix-blend-mode: lighten
}

.mix-blend-color-dodge {
  mix-blend-mode: color-dodge
}

.mix-blend-color-burn {
  mix-blend-mode: color-burn
}

.mix-blend-hard-light {
  mix-blend-mode: hard-light
}

.mix-blend-soft-light {
  mix-blend-mode: soft-light
}

.mix-blend-difference {
  mix-blend-mode: difference
}

.mix-blend-exclusion {
  mix-blend-mode: exclusion
}

.mix-blend-hue {
  mix-blend-mode: hue
}

.mix-blend-saturation {
  mix-blend-mode: saturation
}

.mix-blend-color {
  mix-blend-mode: color
}

.mix-blend-luminosity {
  mix-blend-mode: luminosity
}

.mix-blend-plus-lighter {
  mix-blend-mode: plus-lighter
}

.\!shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.outline {
  outline-style: solid
}

.outline-dashed {
  outline-style: dashed
}

.outline-dotted {
  outline-style: dotted
}

.outline-double {
  outline-style: double
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.\!invert {
  --tw-invert: invert(100%) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.sepia {
  --tw-sepia: sepia(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.\!filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter-none {
  filter: none
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-grayscale {
  --tw-backdrop-grayscale: grayscale(100%);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-invert {
  --tw-backdrop-invert: invert(100%);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-sepia {
  --tw-backdrop-sepia: sepia(100%);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-filter-none {
  -webkit-backdrop-filter: none;
          backdrop-filter: none
}

.\!transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.content-\[\'this-is-also-valid\]-weirdly-enough\'\] {
  --tw-content: 'this-is-also-valid]-weirdly-enough';
  content: var(--tw-content)
}

.\[a-zA-Z0-9\:\\\\-\\\\\._\$\] {
  a-z-a--z0-9: \\-\\. $
}

.\[contenthash\:8\] {
  contenthash: 8
}

.\[hash\:base64\] {
  hash: base64
}

.\[vite\:css\] {
  vite: css
}

.\[vite\:html\] {
  vite: html
}

.hover\:bg-teal-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 103 97 / var(--tw-bg-opacity))
}

.hover\:font-bold:hover {
  font-weight: 700
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(99 99 99 / var(--tw-text-opacity))
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(211 43 67 / var(--tw-text-opacity))
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.hover\:underline:hover {
  text-decoration-line: underline
}

.hover\:no-underline:hover {
  text-decoration-line: none
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.before\:hover\:text-center:hover::before {
  content: var(--tw-content);
  text-align: center
}

.hover\:before\:text-center:hover::before {
  content: var(--tw-content);
  text-align: center
}

.focus\:text-red-700:focus {
  --tw-text-opacity: 1;
  color: rgb(211 43 67 / var(--tw-text-opacity))
}

.focus\:hover\:text-center:hover:focus {
  text-align: center
}

.hover\:focus\:text-center:focus:hover {
  text-align: center
}

.active\:text-red-700:active {
  --tw-text-opacity: 1;
  color: rgb(211 43 67 / var(--tw-text-opacity))
}

.group:hover .group-hover\:no-underline {
  text-decoration-line: none
}

@media print {
  .print\:hidden {
    display: none
  }
}

@media (min-width: 451px) {
  .desktop\:order-1 {
    order: 1
  }

  .desktop\:order-2 {
    order: 2
  }

  .desktop\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .desktop\:m-0 {
    margin: 0px
  }

  .desktop\:mx-0 {
    margin-left: 0px;
    margin-right: 0px
  }

  .desktop\:mx-18 {
    margin-left: 4.5rem;
    margin-right: 4.5rem
  }

  .desktop\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .desktop\:my-0 {
    margin-top: 0px;
    margin-bottom: 0px
  }

  .desktop\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .desktop\:-mt-3 {
    margin-top: -0.75rem
  }

  .desktop\:mb-0 {
    margin-bottom: 0px
  }

  .desktop\:mb-2 {
    margin-bottom: 0.5rem
  }

  .desktop\:mb-24 {
    margin-bottom: 6rem
  }

  .desktop\:mb-3 {
    margin-bottom: 0.75rem
  }

  .desktop\:mb-4 {
    margin-bottom: 1rem
  }

  .desktop\:mb-6 {
    margin-bottom: 1.5rem
  }

  .desktop\:mb-8 {
    margin-bottom: 2rem
  }

  .desktop\:ml-2 {
    margin-left: 0.5rem
  }

  .desktop\:ml-3 {
    margin-left: 0.75rem
  }

  .desktop\:mr-10 {
    margin-right: 2.5rem
  }

  .desktop\:mr-2 {
    margin-right: 0.5rem
  }

  .desktop\:mr-3 {
    margin-right: 0.75rem
  }

  .desktop\:mr-6 {
    margin-right: 1.5rem
  }

  .desktop\:mt-0 {
    margin-top: 0px
  }

  .desktop\:mt-1 {
    margin-top: 0.25rem
  }

  .desktop\:mt-10 {
    margin-top: 2.5rem
  }

  .desktop\:mt-105 {
    margin-top: 0.375rem
  }

  .desktop\:mt-12 {
    margin-top: 3rem
  }

  .desktop\:mt-20 {
    margin-top: 5rem
  }

  .desktop\:mt-3 {
    margin-top: 0.75rem
  }

  .desktop\:mt-5 {
    margin-top: 1.25rem
  }

  .desktop\:block {
    display: block
  }

  .desktop\:inline-block {
    display: inline-block
  }

  .desktop\:inline {
    display: inline
  }

  .desktop\:flex {
    display: flex
  }

  .desktop\:table-row {
    display: table-row
  }

  .desktop\:hidden {
    display: none
  }

  .desktop\:h-20 {
    height: 5rem
  }

  .desktop\:h-52 {
    height: 13rem
  }

  .desktop\:h-full {
    height: 100%
  }

  .desktop\:min-h-170 {
    min-height: 170px
  }

  .desktop\:w-1\/2 {
    width: 50%
  }

  .desktop\:w-1\/3 {
    width: 33.333333%
  }

  .desktop\:w-1\/4 {
    width: 25%
  }

  .desktop\:w-2\/3 {
    width: 66.666667%
  }

  .desktop\:w-3\/4 {
    width: 75%
  }

  .desktop\:w-5\/12 {
    width: 41.666667%
  }

  .desktop\:w-auto {
    width: auto
  }

  .desktop\:w-px {
    width: 1px
  }

  .desktop\:flex-auto {
    flex: 1 1 auto
  }

  .desktop\:basis-1\/2 {
    flex-basis: 50%
  }

  .desktop\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .desktop\:flex-row {
    flex-direction: row
  }

  .desktop\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .desktop\:flex-col {
    flex-direction: column
  }

  .desktop\:items-center {
    align-items: center
  }

  .desktop\:justify-start {
    justify-content: flex-start
  }

  .desktop\:gap-3 {
    gap: 0.75rem
  }

  .desktop\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .desktop\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .desktop\:rounded {
    border-radius: 0.25rem
  }

  .desktop\:p-5 {
    padding: 1.25rem
  }

  .desktop\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .desktop\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .desktop\:pb-0 {
    padding-bottom: 0px
  }

  .desktop\:pb-24 {
    padding-bottom: 6rem
  }

  .desktop\:pb-5 {
    padding-bottom: 1.25rem
  }

  .desktop\:pl-2 {
    padding-left: 0.5rem
  }

  .desktop\:pr-3 {
    padding-right: 0.75rem
  }

  .desktop\:pt-205 {
    padding-top: 0.625rem
  }

  .desktop\:pt-52 {
    padding-top: 13rem
  }

  .desktop\:pt-8 {
    padding-top: 2rem
  }

  .desktop\:text-left {
    text-align: left
  }

  .desktop\:text-center {
    text-align: center
  }

  @media (min-width: 451px) {
    .desktop\:desktop\:text-right {
      text-align: right
    }
  }

  .desktop\:text-right {
    text-align: right
  }

  .desktop\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
  }

  .desktop\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
  }

  .desktop\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  .desktop\:text-5xl {
    font-size: 3rem;
    line-height: 1
  }

  .desktop\:text-6xl {
    font-size: 3.75rem;
    line-height: 1
  }

  .desktop\:text-base {
    font-size: 1rem;
    line-height: 1.5rem
  }

  .desktop\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
  }

  .desktop\:font-bold {
    font-weight: 700
  }
}
